/* eslint-disable func-names */
// import "./lib/selectize";
// import ionRangeSlider from "ion-rangeslider";

export default function controls() {

    (function inputNumber() {
      const inputDivs = document.querySelectorAll('.input-number');
      if(!inputDivs) return;

      /* eslint-disable */
      for (let i = 0; i < inputDivs.length; i++) {

        const input = inputDivs[i].querySelector('input');
        const minus = inputDivs[i].querySelector('.minus');
        const plus = inputDivs[i].querySelector('.plus');

        function setValue(count) {
          count = count < 1 ? 1 : count;
          input.value = parseInt(count, 10) || 1;
        }

        input.addEventListener('change', () => {
          let val = input.value;
          setValue(val);
        });

        minus.addEventListener('click', function () {
          const count = parseInt(input.value, 10) - 1;
          setValue(count);
          input.dispatchEvent(new Event('change'));
          return false;
        });

        plus.addEventListener('click', function () {
          const count = parseInt(input.value, 10) + 1;
          setValue(count);
          input.dispatchEvent(new Event('change'));
          return false;
        });
      }
      /* eslint-enable */

    })();
        


    (function selectToggle() {
        const products = document.querySelectorAll('.product-item');
        if(!products) return;

        global.variantTabs = function(objects = null) {

          if(!objects) return;

          function run(elements) {
            const items = elements.querySelectorAll('.product-item-options__value');
            const values = elements.querySelectorAll('.product-item-variant');

            elements.addEventListener('click', (e) => {
                e.preventDefault();
                let hashed = null;

                for (let index = 0; index < items.length; index++) {
                  const btn = items[index];
                  
                  if(e.target === btn) {
                    hashed = btn.hash
                    btn.classList.add('selected', e.target === btn);
                    for (let i = 0; i < items.length; i++) {
                      const value = items[i];
                      value.classList.remove('selected')
                    }
                    btn.classList.add('selected', e.target === btn);
                  }
                }
                if(!hashed) return;
                for (let i = 0; i < values.length; i++) {
                  const val = values[i];
                  val.classList.remove('selected') 
                }
                elements.querySelector(hashed).classList.add('selected');
                
            })
          }

          for (let i = 0; i < objects.length; i++) {
            const element = objects[i];
            run(element)
            
          }

        }

        global.variantTabs(products)
    })();


    
    (function mobileMenu() {
      const button = document.querySelector("[data-mobile-button]");
      if (!button) return;
  
      const header = button.closest(".header");
      const list = header.querySelector(".menu-list");
  
      button.addEventListener("click", (e) => {
        e.preventDefault();
        header.classList.toggle("menu_show");
        list.classList.toggle("list_show");
      });
    })();

    // (function navToggle() {
    //     const btn = document.querySelector('.menu-toggle');
    //     // const menuList = document.querySelector('.mobile-menu-list');
    //     const header = document.querySelector('.header');

    //     btn.addEventListener('click', function() {
    //         header.classList.toggle('menu-open')
    //     });

    //     // menuList.addEventListener('click', function(e) {
    //     //     if(e.target.classList.contains('mobile-menu-list-item')) {
    //     //         header.classList.toggle('menu-open')
    //     //     }
            
    //     // })


    // })();

    // (function headerSticky() {
    //     const {body} = document;
    //     const scrollUp = "scroll-up";
    //     const scrollDown = "scroll-down";
    //     const header = document.querySelector('.header')
    //     let lastScroll = 0;

    //     window.addEventListener("scroll", () => {
    //         if(header.classList.contains('menu-open')) return;
    //         const currentScroll = window.pageYOffset;
    //         if (currentScroll <= 300) {
    //             body.classList.remove(scrollUp);
    //             return;
    //         }
            
    //         if (currentScroll > lastScroll && currentScroll > 300 && !body.classList.contains(scrollDown)) {
    //             // down
    //             body.classList.remove(scrollUp);
    //             body.classList.add(scrollDown);
    //         } else if (currentScroll < lastScroll && currentScroll > 300 && body.classList.contains(scrollDown)) {
    //             // up
    //             body.classList.remove(scrollDown);
    //             body.classList.add(scrollUp);
    //         }
    //         lastScroll = currentScroll;
    //     });
    // })();


    // (function getContent() {

    //     function evalJSFromHtml(html) {
    //       const newElement = document.createElement('div');
    //       newElement.innerHTML = html;
        
    //       const scripts = newElement.getElementsByTagName("script");
    //       for (let i = 0; i < scripts.length; ++i) {
    //         const script = scripts[i];
    //         // eslint-disable-next-line no-eval
    //         eval(script.innerHTML);
    //       }
    //     }

    //     $('.ajax_load').on('click', function(e) {
    //         e.preventDefault();
    //         const activeClass = this.dataset.activeClass || 0
    //         if(this.classList.contains(activeClass)) return;
    //         const loadURL = this.dataset.ajaxFrom
    //         if(!loadURL) return;
    //         const appending = this.dataset.ajaxAppend || 0
    //         const pageTo = this.dataset.ajaxTo || null
    //         const hideIt = this.dataset.ajaxHide || null
    //         const wrapper = document.querySelector(pageTo) || null
    //         const hideThis = document.querySelector(hideIt)
    //         if(wrapper) {
    //             wrapper.classList.add('loader')
    //         }
    //         if(activeClass) {
    //             this.parentElement.querySelectorAll('.ajax_load').forEach(n => n.classList.toggle(activeClass, n === e.target));
    //         }
    //         // this.classList.add('active')
        
    //         $.ajax({
    //         type: "GET",
    //         url: loadURL,
    //         dataType: "html",
    //         success(data) {
                
    //             if(appending === '1') {
    //             wrapper.insertAdjacentHTML('beforeend', data);
    //             wrapper.classList.remove('loader')
    //             } else {
    //             wrapper.innerHTML = data;
    //             wrapper.classList.remove('loader')
    //             }
    //             evalJSFromHtml(data);
    //                 if(hideThis) {
    //                 hideThis.style.display = 'none';
    //             }

    //         }
    //         });
    //     });
    // })();



}