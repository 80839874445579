// import Pristine from "pristinejs";
import "./lib/jquery.maskedinput";
import 'magnific-popup';

export default function modals() {


    $('input[type="tel"]').mask("+7 (999) 999-99-99");
    // (function inputPhoneMask() {
    //     $('input[type="tel"]').mask("+7 (999) 999-99-99", {
    //       completed() {
    //         const formGroup = $(this).closest(".form-group");
    //         formGroup[0].classList.remove("has-danger");
    //         formGroup.find(".pristine-error").hide();
    //       },
    //     });
    //   })();


    // product-item-image

    // const tempContent = "";
    // const mfp = $('.product-item-image').magnificPopup();
    $('.product-item-image').on('click', function(event) {
        event.preventDefault();
        const content = $(this).closest('.product-item')
        const contentClone = $(this).closest('.product-item').clone();
        const className = 'selected'

        global.variantTabs(contentClone)
        
        const items = contentClone[0].querySelectorAll('.product-item-options__value');
        const originalItems = content[0].querySelectorAll('.product-item-options__value')

        contentClone[0].addEventListener('click', (e) => {
            const index = Array.from(items).indexOf(e.target);
            if(index >= 0) {  
              originalItems[index].click();
            }
        });

        contentClone[0].querySelector('.product-item-image').addEventListener('click', e => {
          e.preventDefault();
        });

        $.magnificPopup.open({
            midClick: true,
            fixedContentPos: false,
            items: {
              src: contentClone, // can be a HTML string, jQuery object, or CSS selector
              type: 'inline'
            },
            callbacks: {
                open() {
                    
                },
            }
          });
    })
    


    // (function formValidation() {
    //     const formList = document.querySelectorAll("[data-form-validate]");
    
    //     formList.forEach((form) => {
    //       const validation = new Pristine(form);
    //       const successMessage = form.querySelector(".form-success__message");
    //       // const wrapper = form.closest('.request__wrapper') || form.closest('.request-modal')
    //       form.addEventListener("submit", function (e) {
    //         // console.log('submit');
    //         e.preventDefault();
    //         const formData = new FormData(this);
    //         const valid = validation.validate();

    //         if (valid) {
    //           $.ajax({
    //             // dataType: "json",
    //             type: form.method,
    //             url: form.action,
    //             data: formData,
    //             cache: false,
    //             processData: false,
    //             contentType: false,
    //             success(data) {
    //                 form.reset();
    //                 successMessage.innerHTML = 'Ваша заявка успешно отправлена!';
    //                 successMessage.style.display = "block";
    //                 // console.log('success!');
    //                 // wrapper.classList.remove('loader');
    //             },
    //             error(request, status, error) {},
    //           });
    //         } else {
    //           successMessage.style.display = "none";
    //         }
    //       });
    //     });
    //   })();

}