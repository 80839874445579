/* eslint-disable import/no-extraneous-dependenciescamelcase */
import Swiper, { Navigation, Pagination } from "swiper";
/* eslint-enable import/no-extraneous-dependenciescamelcase */

export default function sliders() {

  // (function simpleSlider() {
  //   // Install modules
  //   Swiper.use([Navigation, Pagination]);
  //   const swiper = new Swiper(".simple-slider", {
  //     slidesPerView: 2,
  //     spaceBetween: 15,
  //     watchOverflow: true,
  //     navigation: {
  //       nextEl: ".simple-slider-button-next",
  //       prevEl: ".simple-slider-button-prev",
  //     },
  //     breakpoints: {
  //       768: {
  //         slidesPerView: "auto",
  //         spaceBetween: 70,
  //       },
  //     },
  //   });
  // })();

  (function mainSlider() {
    const swiper = new Swiper(".main-slider", {
      slidesPerView: 1,
      spaceBetween: 20,
      watchOverflow: true,
      loop: true
    });
  })();


}